import { appAnalytics } from 'analytics';
import { ScreenOpened, SubscriptionSuccess } from 'analytics/AllEvents';
import { ServerProduct } from 'bootstrap/data/product/Product.types';
import {
  convertToProduct,
  getAddonsPlansConfig,
} from 'bootstrap/data/product/Product.utils';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { getErrorMessage } from 'bootstrap/net/commonnet';
import { buyAddon } from 'bootstrap/net/subscription/subscription';
import { BuyAddonPlan } from 'cancelsubscription/analytics';

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PlanSelectType } from 'subscription/singleproduct/components/plansection/PlanSection.constants';
import { useFeatures } from 'useFeatures';
import { pushWithSearchQuery } from 'utils';

interface useAddonsLogicProps {
  planCode?: string;
  isDiscountApplied?: boolean;
  setDiscountPopupShown?: (isDiscountApplied: boolean) => void;
}

export const useAddonsLogic = ({
  planCode,
  isDiscountApplied,
  setDiscountPopupShown,
}: useAddonsLogicProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const addonsPlans = getAddonsPlansConfig();

  const { email } = useAppSelector((state) => state.user);

  const workbookProductCode = 'co-loona-web-nc-workbook';

  const workbookProduct: ServerProduct =
    // @ts-ignore
    addonsPlans?.items?.[workbookProductCode];

  const convertedWorkbookProduct = convertToProduct(workbookProduct);

  const { currency, planType } = useAppSelector((state) => state.user);

  const { upsellFlow } = useFeatures();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    appAnalytics.trackEvent(
      new ScreenOpened(
        `${location.pathname}${isDiscountApplied ? '-withDiscount' : ''}`,
      ),
    );
  }, []);

  const goToNextPage = () => {
    dispatch(pushWithSearchQuery('/done'));
  };

  const onAddonBuyClickHandler = async () => {
    setIsLoading(true);

    try {
      const result = await buyAddon({
        planCode: planCode || 'co-loona-web-nc-workbook',
        currency,
      });

      appAnalytics.trackEvent(
        new SubscriptionSuccess(
          convertedWorkbookProduct.amount,
          convertedWorkbookProduct.currency,
          convertedWorkbookProduct.code,
          {
            eventId: result?.purchase?.order_id,
            email: email,
            onboardingType: 'pi',
          },
        ),
      );
      appAnalytics.trackEvent(new BuyAddonPlan(planCode as string));

      if (upsellFlow) {
        if (location.pathname.includes('quarter-upsell')) {
          goToNextPage();
        } else if (
          planType === PlanSelectType.MONTH ||
          planType === PlanSelectType.WEEK
        ) {
          dispatch(pushWithSearchQuery('/quarter-upsell'));
        } else {
          if (setDiscountPopupShown) {
            setDiscountPopupShown(true);
          }
        }
      } else {
        goToNextPage();
      }
      setIsLoading(false);
    } catch (error) {
      setError(getErrorMessage(error));
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    planType,
    setError,
    onSkipClickHandler: goToNextPage,
    onAddonBuyClickHandler,
  };
};
