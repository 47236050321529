import { sha256 } from 'utils';
import { ExperimentStarted } from './AllEvents';
import { Analytics } from './Analytics';
import { Event } from './Event';
import { Events } from './Events';
import { appAnalytics } from './index';

type GoogleAnalyticsEventInfo = {
  action: string;
  category?: string;
};

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

const mappedEvents: Record<string, GoogleAnalyticsEventInfo> = {
  [Events.SignUp]: {
    action: 'sign_up',
    category: 'engagement',
  },
  [Events.Subscribe]: {
    action: 'add_payment_info',
    category: 'ecommerce',
  },
  [Events.ContinueWelcome]: {
    action: 'generate_lead',
    category: 'engagement',
  },
  [Events.ContinueBoardingPersonalizationD1]: {
    action: 'select_content',
    category: 'engagement',
  },
  [Events.SubscriptionSuccess]: {
    action: 'purchase',
    category: 'ecommerce',
  },
  [Events.SubscriptionStarted]: {
    action: 'subscription_started',
    category: 'ecommerce',
  },
  [Events.ScreenOpened]: {
    action: 'pageview',
  },
};

export class GoogleAnalytics extends Analytics {
  supportedEvents = [
    Events.SignUp,
    Events.Subscribe,
    Events.ContinueWelcome,
    Events.ContinueBoardingPersonalizationD1,
    Events.SubscriptionSuccess,
    Events.ScreenOpened,
    Events.SubscriptionStarted,
  ];

  constructor() {
    super();

    activateOptimize();
  }

  trackEvent(event: Event) {
    const eventInfo = mappedEvents[event.name] || event.name;
    const params: any = event.params;
    if (event.name === Events.SubscriptionSuccess) {
      params['transaction_id'] = event.optionalParams?.eventId;
      window.dataLayer?.push({
        transaction_id: params['transaction_id'],
        currency_code: params['currency'],
      });

      event.optionalParams?.email &&
        sha256(event.optionalParams.email).then((email) => {
          window.dataLayer?.push({
            email: email,
          });
        });
    }

    window.dataLayer?.push({
      event: eventInfo.action,
      eventProps: {
        category: eventInfo.category,
        action: eventInfo.action,
        value: params,
      },
      ...params,
    });
  }
}

export const activateOptimize = () => {
  // @ts-ignore
  window.dataLayer?.push({ event: 'optimize.activate' });
};

export const initOptimize = () => {
  // @ts-ignore
  gtag('event', 'optimize.callback', {
    callback: (value: any, name: any) => {
      if (name && value) {
        appAnalytics.trackEvent(new ExperimentStarted(name, value));
      }
    },
  });
};

function gtag() {
  // @ts-ignore
  // eslint-disable-next-line
  window.dataLayer?.push(arguments);
}
