export enum Events {
  ScreenOpened = 'viewOnboardingScreen',
  GoBack = 'goBackOnboardingScreen',
  ContinueWelcome = 'clickContinueBoardingWelcome',
  SignUp = 'signUp',
  SignUpError = 'signUpError',
  SwitchPlan = 'switchPlan',
  SwitchBoardingPrice = 'switchBoardingPrice',
  SwitchAddon = 'switchAddon',
  ViewSwitchPlanPrompt = 'viewSwitchPlanPrompt',
  ViewSkipTrialPopup = 'viewSkipTrialPopup',
  ViewSkipBasicUpsellPopup = 'viewSkipBasicUpsellPopup',
  ViewSkipWeekMonthUpsellPopup = 'viewSkipWeekMonthUpsellPopup',
  ReplySwitchPlanPrompt = 'replySwitchPlanPrompt',
  UnfoldPayment = 'continueUnfoldPaymentMethod',
  ViewWhyNowPrompt = 'viewWhyNowPrompt',
  Subscribe = 'continueSubscribe',
  SubscriptionSuccess = 'subscriptionSuccess',
  SubscriptionStarted = 'subscriptionStarted',
  SubscriptionError = 'subscriptionFail',
  ViewCongrats = 'viewInstallCongratsScreen',
  Install = 'clickContinueInstall',
  InstallQR = 'clickInstallQR',
  ExperimentStarted = 'experimentEntered',
  PageView = 'pageview',
  ViewPIScreen = 'viewPIScreen',
  ViewQPScreen = 'viewQPScreen',
  ViewInstallQRScreen = 'viewInstallQRScreen',
  ContinuePIWelcome = 'clickContinuePIWelcome',
  ContinueQPOffer = 'clickContinueQPOffer',
  RecaptchaError = 'recaptchaError',

  ContinueBoardingAboutYouGuided = 'continueBoardingAboutYouGuided',
  ContinueBoardingAboutYouD1 = 'continueBoardingAboutYouD1',
  ContinueBoardingAboutYouGender = 'continueBoardingAboutYouGender',
  ContinueBoardingAboutYouAge = 'continueBoardingAboutYouAge',
  ContinueBoardingAboutYouRoutine = 'continueBoardingAboutYouRoutine',
  ContinueBoardingAboutYouD2 = 'continueBoardingAboutYouD2',
  ContinueBoardingAboutYouKids = 'continueBoardingAboutYouKids',
  ContinueBoardingAboutYouD3 = 'continueBoardingAboutYouD3',
  ContinueBoardingAboutYouActivity = 'continueBoardingAboutYouActivity',
  ContinueBoardingAboutYouD4 = 'continueBoardingAboutYouD4',
  ContinueBoardingAboutYouProof1 = 'continueBoardingAboutYouProof1',
  ContinueBoardingSleepAndActivityQ1 = 'continueBoardingSleepAndActivityQ1',
  ContinueBoardingSleepAndActivityQ2 = 'continueBoardingSleepAndActivityQ2',
  ContinueBoardingSleepAndActivityD1 = 'continueBoardingSleepAndActivityD1',
  ContinueBoardingSleepAndActivityQ3 = 'continueBoardingSleepAndActivityQ3',
  ContinueBoardingSleepAndActivityProof1 = 'continueBoardingSleepAndActivityProof1',
  ContinueBoardingSleepAndActivityProof2 = 'continueBoardingSleepAndActivityProof2',
  ContinueBoardingSleepAndActivityCoffee = 'continueBoardingSleepAndActivityCoffee',
  ContinueBoardingSleepAndActivityDinner = 'continueBoardingSleepAndActivityDinner',
  ContinueBoardingSleepAndActivityAfterMidnight = 'continueBoardingSleepAndActivityAfterMidnight',
  ContinueBoardingSleepAndActivityLessThanEightHours = 'continueBoardingSleepAndActivityLessThanEightHours',
  ContinueBoardingSleepAndActivityExtraMotivation = 'continueBoardingSleepAndActivityExtraMotivation',
  ContinueBoardingSleepAndActivityD2 = 'continueBoardingSleepAndActivityD2',
  ContinueBoardingSleepAndActivityLoader = 'continueBoardingSleepAndActivityLoader',

  ContinueBoardingPersonalizationD1 = 'continueBoardingPersonalizationD1',
  ContinueBoardingPersonalizationColors1 = 'continueBoardingPersonalizationColors1',
  ContinueBoardingPersonalizationColors2 = 'continueBoardingPersonalizationColors2',
  ContinueBoardingPersonalizationColors3 = 'continueBoardingPersonalizationColors3',
  ContinueBoardingPersonalizationColors4 = 'continueBoardingPersonalizationColors4',
  ContinueBoardingPersonalizationProof1 = 'continueBoardingPersonalizationProof1',
  ContinueBoardingPersonalizationArtwork1 = 'continueBoardingPersonalizationArtwork1',
  ContinueBoardingPersonalizationArtwork2 = 'continueBoardingPersonalizationArtwork2',
  ContinueBoardingPersonalizationArtwork3 = 'continueBoardingPersonalizationArtwork3',
  ContinueBoardingPersonalizationArtwork4 = 'continueBoardingPersonalizationArtwork4',
  ContinueBoardingPersonalizationNarrative = 'continueBoardingPersonalizationNarrative',
  ContinueBoardingPersonalizationLoader = 'continueBoardingPersonalizationLoader',
  ContinueBoardingPersonalizationD2 = 'continueBoardingPersonalizationD2',
  ContinueBoardingPersonalizationGoal = 'continueBoardingPersonalizationGoal',
  ContinueBoardingPersonalizationPlaces = 'continueBoardingPersonalizationPlaces',
  ContinueBoardingPersonalizationLength = 'continueBoardingPersonalizationLength',
  ContinueBoardingPersonalizationFrequency = 'continueBoardingPersonalizationFrequency',
  ContinueBoardingPersonalizationFeelingHoping = 'continueBoardingPersonalizationFeelingHoping',
  ContinueBoardingPersonalizationSounds = 'continueBoardingPersonalizationSounds',
  ContinueBoardingPersonalizationFeeling = 'continueBoardingPersonalizationFeeling',
  ContinueBoardingPersonalizationD3 = 'continueBoardingPersonalizationD3',

  ContinueBoardingRMeqQ1 = 'continueBoardingRMeqQ1',
  ContinueBoardingRMeqQ2 = 'continueBoardingRMeqQ2',
  ContinueBoardingRMeqQ3 = 'continueBoardingRMeqQ3',
  ContinueBoardingRMeqQ4 = 'continueBoardingRMeqQ4',
  ContinueBoardingRMeqQ5 = 'continueBoardingRMeqQ5',

  ContinueBoardingEduD1 = 'continueBoardingEduD1',
  ContinueBoardingEduQ1 = 'continueBoardingEduQ1',
  ContinueBoardingEduQ2 = 'continueBoardingEduQ2',
  ContinueBoardingEduQ3 = 'continueBoardingEduQ3',
  ContinueBoardingEduQ4 = 'continueBoardingEduQ4',
  ContinueBoardingEduQ5 = 'continueBoardingEduQ5',

  ContinueBoardingIsiD1 = 'continueBoardingIsiD1',
  ContinueBoardingIsiQ1 = 'continueBoardingIsiQ1',
  ContinueBoardingIsiQ2 = 'continueBoardingIsiQ2',
  ContinueBoardingIsiQ3 = 'continueBoardingIsiQ3',
  ContinueBoardingIsiQ4 = 'continueBoardingIsiQ4',
  ContinueBoardingIsiQ5 = 'continueBoardingIsiQ5',

  ContinueBoardingEduQ1Answer = 'continueBoardingEduQ1Answer',
  ContinueBoardingEduQ2Answer = 'continueBoardingEduQ2Answer',
  ContinueBoardingEduQ3Answer = 'continueBoardingEduQ3Answer',
  ContinueBoardingEduQ4Answer = 'continueBoardingEduQ4Answer',
  ContinueBoardingEduQ5Answer = 'continueBoardingEduQ5Answer',

  ContinueBoardingGadQ1 = 'continueBoardingGadQ1',
  ContinueBoardingGadQ2 = 'continueBoardingGadQ2',
  ContinueBoardingGadQ3 = 'continueBoardingGadQ3',
  ContinueBoardingGadQ4 = 'continueBoardingGadQ4',
  ContinueBoardingGadQ5 = 'continueBoardingGadQ5',
  ContinueBoardingGadQ6 = 'continueBoardingGadQ6',
  ContinueBoardingGadQ7 = 'continueBoardingGadQ7',

  ContinueBoardingBlitzArt = 'continueBoardingBlitzArt',
  ContinueBoardingBlitzDaydream = 'continueBoardingBlitzDaydream',
  ContinueBoardingBlitzTinnitus = 'continueBoardingBlitzTinnitus',
  ContinueBoardingBlitzRead = 'continueBoardingBlitzRead',
  ContinueBoardingBlitzShiftWork = 'continueBoardingBlitzShiftWork',
  ContinueBoardingBlitzADHD = 'continueBoardingBlitzADHD',

  BlitzAlertViewed = 'viewBlitzPopUp',
  ContinueBoardingSkipTrial = 'continueBoardingSkipTrial',
  ProductSelected = 'continueBoardingPrice',
  ContinueBoardingPricePopUp = 'continueBoardingPricePopUp',

  ContinueUnfoldPaymentMethod = 'continueUnfoldPaymentMethod',
  PayPalClicked = 'clickPayPalButton',
  ApplePayClicked = 'clickApplePayButton',
  VenmoClicked = 'clickVenmoButton',
  CardFormInputStarted = 'clickCreditCardInput',
  UnfoldFaq = 'unfoldFaq',

  ViewPopup = 'viewPopup',
  ClickPreventGoBack = 'clickPreventGoBack',

  GiftScreenOpened = 'viewGiftScreen',
  GiftProductSelected = 'continueGiftWelcome',
  GiftAmountSelected = 'continueGiftPlanSettings',
  GiftPurchaseSuccess = 'giftPurchase',

  RedeemScreenOpened = 'viewRedeemScreen',
  RedeemWelcomeContinue = 'continueRedeemWelcome',
  RedeemSignUp = 'signUp',
  RedeemGiftCodeActivated = 'ContinueGiftCode',
  RedeemAppContinue = 'ContinueRedeemCongratsScreen',

  SubscriptionCancelScreenOpened = 'viewCancelScreen',
  ContinueSubscriptionCancel = 'clickContinueCancel',
  ContinueSubscriptionCancelReason = 'clickContinueCancelReason',
  ContinueSubscriptionCancelHowLikely = 'clickContinueCancelHowLikely',
  SubscriptionProlongSuccess = 'subscriptionProlongSuccess',
  SubscriptionSwitchSuccess = 'subscriptionSwitchSuccess',
  BuyAddonPlan = 'buyAddonPlan',
}
