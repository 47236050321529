import ReactPixel from 'react-facebook-pixel';
import { getCookie, sha256 } from 'utils';
import { Analytics } from './Analytics';
import { Events } from './Events';
import { Event } from './Event';

const FACEBOOK_PIXEL_ID = process.env.REACT_APP_FACEBOOK_PIXEL_ID || '';

const mappedEvents: Record<string, string> = {
  [Events.SignUp]: 'CompleteRegistration',
  [Events.Subscribe]: 'AddPaymentInfo',
  [Events.ContinueWelcome]: 'Lead',
  [Events.ContinueBoardingPersonalizationD1]: 'ViewContent',
  [Events.SubscriptionSuccess]: 'Purchase',
  [Events.SubscriptionStarted]: 'SubscriptionStarted',
};

declare global {
  interface AdvancedMatching {
    ct: string;
    country: string;
    db: string;
    em: string;
    fn: string;
    ge: string;
    ln: string;
    ph: string;
    st: string;
    zp: string;
    fbp: string;
    subscription_id: string;
  }
}

export class FacebookPixel extends Analytics {
  advancedMatching: AdvancedMatching = {
    ct: '',
    country: '',
    db: '',
    em: '',
    fn: '',
    ge: '',
    ln: '',
    ph: '',
    st: '',
    zp: '',
    fbp: '',
    subscription_id: '',
  };

  supportedEvents = [
    Events.SignUp,
    Events.Subscribe,
    Events.Install,
    Events.ContinueWelcome,
    Events.ContinueBoardingPersonalizationD1,
    Events.SubscriptionSuccess,
    Events.SubscriptionStarted,
  ];

  constructor() {
    super();
    ReactPixel.init(FACEBOOK_PIXEL_ID, undefined, {
      debug: process.env.NODE_ENV !== 'production',
      autoConfig: true,
    });
  }

  trackEvent(event: Event) {
    const eventName = mappedEvents[event.name];

    if (!this.advancedMatching.fbp) {
      this.advancedMatching.fbp = getCookie('_fbp');
    }

    const track = () => {
      if (event.optionalParams?.eventId) {
        ReactPixel.fbq(
          'track',
          eventName,
          typeof event.params === 'object'
            ? {
                ...event.params,
                subscription_id: event.optionalParams.eventId,
              }
            : event.params,
          {
            eventID: event.optionalParams.eventId,
          },
        );
      } else {
        ReactPixel.track(eventName, event.params);
      }
    };
    if (!!event.optionalParams) {
      const hashPromises: Promise<string>[] = [];
      event.optionalParams.email &&
        hashPromises.push(sha256(event.optionalParams.email));
      event.optionalParams.zip &&
        hashPromises.push(sha256(event.optionalParams.zip));
      event.optionalParams.firstName &&
        hashPromises.push(sha256(event.optionalParams.firstName.toLowerCase()));
      event.optionalParams.lastName &&
        hashPromises.push(sha256(event.optionalParams.lastName.toLowerCase()));
      Promise.allSettled(hashPromises)
        .then(([email, zip, firstName, lastName]) => {
          email.status === 'fulfilled' &&
            (this.advancedMatching.em = email.value);
          zip.status === 'fulfilled' && (this.advancedMatching.zp = zip.value);
          firstName.status === 'fulfilled' &&
            (this.advancedMatching.fn = firstName.value);
          lastName.status === 'fulfilled' &&
            (this.advancedMatching.ln = lastName.value);

          ReactPixel.init(FACEBOOK_PIXEL_ID, this.advancedMatching, {
            debug: process.env.NODE_ENV !== 'production',
            autoConfig: true,
          });
          track();
        })
        .catch(() => {
          track();
        });
    } else {
      track();
    }
  }
}
